.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-container {
    background-color: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    width: 90%;
    max-width: 400px;
  }
  
  h1 {
    color: #ff6347;
    margin-bottom: 10px;
  }
  
  p {
    color: #333;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .refresh-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .refresh-link:hover {
    background-color: #0056b3;
  }
  