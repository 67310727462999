/* Styles pour TermsAndConditions */
.terms-container {
    max-width: 800px;  /* Limite la largeur maximale de la section à 800px */
    margin: 0 auto;    /* Centre horizontalement la section sur la page */
    padding: 20px;     /* Ajoute des espaces internes */
    background:none;  /* Arrière-plan légèrement gris pour contraster */

    border-radius: 8px; /* Bords arrondis */
    font-family: 'Arial', sans-serif;  /* Police simple et lisible */
    line-height: 1.6;  /* Espacement de ligne pour faciliter la lecture */
  }
  
  /* Style pour les titres */
  .terms-container h1 {
    font-size: 2rem;  /* Taille du titre principal */
    margin-bottom: 20px;  /* Espacement sous le titre */
    text-align: center;  /* Centrer les titres */
    color: #333;  /* Couleur du texte en gris foncé */
  }
  
  .terms-container h2 {
    font-size: 1.5rem;  /* Taille des sous-titres */
    margin-top: 20px;   /* Espacement avant les sous-titres */
    margin-bottom: 10px;
    color: #555;  /* Couleur des sous-titres en gris légèrement plus clair */
  }
  
  .terms-container h3 {
    font-size: 1.3rem;  /* Taille des sous-sous-titres */
    margin-top: 15px;  /* Espacement avant les sous-sous-titres */
    margin-bottom: 8px;
    color: #777;  /* Couleur en gris clair */
  }
  
  /* Style pour les paragraphes */
  .terms-container p {
    font-size: 1rem;  /* Taille du texte des paragraphes */
    color: #444;  /* Couleur du texte */
    margin-bottom: 15px;  /* Espacement sous chaque paragraphe */
    text-align: justify;  /* Justifie le texte pour un rendu propre */
  }

  .custom-link {
    font-size: 0.8em; /* Smaller font size */
    color: blue; /* Blue color for the link */
    text-decoration: underline; /* Underline the link */
  }
  
  
  