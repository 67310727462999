.entire {
  margin: 0px -5%;
  width: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  align-items: center;
  justify-content: stretch;
  background: url('../../../public/Pictures/bacgroundLogin.png') no-repeat center center fixed;
  background-size: cover;
}

.logo-section {
  text-align: center;
  margin-bottom: 20px;
  /* Space between the logo and the form container */
}

.login-logo {
  max-width: 100%;
  height: auto;
}

.tagline {
  margin-top: 5px;
  color: #fff;
  font-size: 1.5em;
  font-weight: 400;
}

.login-container {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  width: 850px;
  height: auto;
  min-height: 400px;
}


  .content-box {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    border: none;
    background: none;
  }

  .active-tab {
    border-bottom: 2px solid #28C4D1;
    outline: none;
  }

  .form-box {
    margin-top: 20px;
    max-height: calc(100% - 50px);
    /* Prevent overflow */
    display: flex;
    flex-direction: column;
  }

  .input_field_login,
  .input_field_select,
  .submit-button {
    height: auto;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  #input_field_login_date {
    height: 20px;
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .input_field_login {
    width: 90%;
  }

  .input_field_select {
    width: 96%;
    color: gray;

  }

  .submit-button {
    width: 70%;
    padding: 10px;
    background-color: var(--main-sidebar-background-color);
    color: #ffffff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }

  .submit-button:hover {
    background-color: #0097a7;
  }

  .error-text {
    color: red;
    margin-top: 10px;
  }

  .side-title {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E3FDFF;
    color: white;
    padding: 40px;
    border-radius: 10px;
    background-image: url('../../../public/Pictures/Image_3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: saturate(110%);
  }

  .side-title h4 {
    font-family: Roboto Flex;
    font-size: 37px;
    font-weight: 700;
    line-height: 48.1px;
    text-align: center;
    margin-bottom: -170px;
  }

  .terms-text {
    margin-top: 20px;
    font-size: 0.8em;
    color: #333;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .back-button {
    align-self: flex-end;
    height: 37px;
    padding: 5px 10px;
    background-color: #f1f1f1;
    color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;

  }

  .back-button:hover {
    background-color: #ddd;
  }

  .forgot_login_section_login {
    margin-top: 20px;
    color: gray
  }

  .forgot_span_login_section_login {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }


  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
  }

  .close-popup-button {
    margin-top: 10px;
    padding: 10px 20px;
    background: var(--main-sidebar-background-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .copy-code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Space between code and button */
    margin-top: 10px;
  }

  .copy-button {
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #28C4D1;
    /* Matches the active tab color */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .copy-button:hover {
    background-color: #28C4D1;
    color: white;
    border-color: #28C4D1;
  }

  .copy-code-container strong {
    font-size: 1.1rem;
    color: #333;
  }


  /* Forgot Password Button */
  .forgot-password-button {
    background: none;
    border: none;
    color: #007BFF;
    /* Blue color for a link-like appearance */
    cursor: pointer;
    font-size: 0.9em;
    margin-top: 10px;
    text-decoration: underline;
  }

  .forgot-password-button:hover {
    color: #0056b3;
    /* Darker blue on hover */
  }

  /* Forgot Password Form */
  .forgot-password-form {
    margin-top: 20px;
  }

  .forgot-password-form .input_field_login {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .forgot-password-form .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #28C4D1;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .forgot-password-form .submit-button:hover {
    background-color: #0097a7;
  }

  .forgot-password-form .back-button {
    margin-top: 10px;
    background: #f8f9fa;
    color: #333;
    border: 1px solid #ccc;
    width: 100%;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }

  .forgot-password-form .back-button:hover {
    background-color: #e9ecef;
  }




  .form-message {
    position: relative;
    margin: 0 auto;
    top: 0;
    /* Place le message au-dessus du formulaire */
    background-color: #f8d7da;
    /* Rouge clair pour les erreurs */
    color: #721c24;
    /* Rouge foncé pour le texte */
    border: 1px solid #f5c6cb;
    /* Bordure rouge clair */
    padding: 15px 25px;
    /* Augmente l'espace intérieur */
    border-radius: 8px;
    /* Bordure plus arrondie */
    font-size: 16px;
    /* Texte légèrement plus grand */
    text-align: center;
    width: 85%;
    /* Plus large que le formulaire */
    animation: popInOut 5s ease-in-out;
    /* Animation plus visible */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Ajoute une ombre subtile */
    z-index: 10;
    /* Assure qu'il reste au-dessus des autres éléments */
  }

  @keyframes popInOut {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }

    10% {
      transform: scale(1.05);
      /* Légère mise en avant au début */
      opacity: 1;
    }

    90% {
      transform: scale(1);
      /* Retour à la taille normale */
      opacity: 1;
    }

    100% {
      transform: scale(0.8);
      /* Réduction avant disparition */
      opacity: 0;
    }
  }

  /* Optionnel : Différencier les avertissements */
  .form-message.warning {
    background-color: #fff4cc;
    /* Jaune clair pour les warnings */
    color: #996600;
    /* Jaune foncé pour le texte */
    border: 1px solid #ffeeba;
    /* Bordure jaune clair */
  }


  /* Phone input styling */
  .phone-input-container {
    display: flex;
    margin-bottom: 15px;
    width: 96%;
    max-height: 40px;
  }

  .phone-region-select {
    flex: 0 0 auto;
    width: 40%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    background-color: #f9f9f9;
    font-size: 14px;
  }

  .phone-number-input {
    flex: 1;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
  }

  /* For responsive design */
  @media (max-width: 768px) {
    .phone-input-container {
      flex-direction: column;
    }

    .phone-region-select,
    .phone-number-input {
      width: 100%;
      border-radius: 4px;
      margin-bottom: 10px;
    }
  }