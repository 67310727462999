/* Container */
.payment-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  font-family: "Arial", sans-serif;
  text-align: left;
}

/* Title */
.payment-container h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.payment-container p {
  text-align: center;
  color: #333;
}

/* Redirect Notice */
.redirect-notice {
  text-align: center;
  font-size: 14px;
  color: #007bff;
  font-weight: 500;
  margin-bottom: 20px;
}

/* Form */
.payment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #555;
  margin-bottom: 5px;
  text-align: end; 
  display: block; 
}

.payment-form input {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  text-align: end;
}

.payment-form input:focus {
  border-color: #007bff;
  outline: none;
}

/* Button */
.pay-button {
  background-color: #007bff;
  color: white;
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  transition: background 0.3s ease;
}

.pay-button:hover {
  background-color: #0056b3;
}

.pay-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Error Message */
.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

/* Responsive */
@media screen and (max-width: 600px) {
  .payment-container {
    width: 90%;
    padding: 20px;
  }
}

/* Popup overlay */
.popup2-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup2-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 35%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .popup2-content {
    width: 90%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-popup2-button {
  background-color: #6c757d;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  transition: background 0.3s ease;
  margin-top: 10px;
}

.close-popup2-button:hover {
  background-color: #5a6268;
}
