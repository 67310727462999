.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Assure que le conteneur occupe toute la hauteur de la fenêtre */
    background-color: #f4f4f9; /* Optionnel : couleur de fond */
  }
  

.reset-password-form {
    background-color: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
  }
  
  .reset-password-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .reset-password-button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reset-password-button:hover {
    background-color: #0056b3;
  }
  
  .reset-password-error {
    font-size: 14px;
    margin-top: 10px;
    color: red;
  }
  
  .reset-password-success {
    font-size: 14px;
    margin-top: 10px;
    color: green;
  }
