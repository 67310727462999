:root {
  --main-black: #121212;
  --main-purple: #9f6195;
  --main-salad: #28c4d1;
  --font-size-headline: 22px;
  --font-size-category-menu: 18px;
  --font-size-category-sub-menu: 16px;
  --font-size-text: 18px;
  /* --gradient-background: linear-gradient(0deg,
      #19edff 9%,
      #28c4d1 25%,
      #15676e 69%,
      #000000 150%);
      --gradient-background-270: linear-gradient(270deg,
      #19edff 9%,
      #28c4d1 25%,
      #15676e 69%,
      #000000 150%); */
  --main-hover: rgba(159, 97, 149, 1);
  --line-height-headline: "auto";
  --line-height-menu: 130%;
  --line-height-sub-menu: 130%;
  --line-height-text: 26px;
  --input-background-color:rgba(217,217,217,0.45);
  --main-sidebar-background-color:  #115d63;
  --main-table-header: #FAFAFA;

}


.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}
.container_column {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  text-align: center;

}
.container_row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction:row;
  text-align: center;

}

.container_align_start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.container_align_end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page_title {
  font-size: 52px;
  color: var(--main-black);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.second_title {
  margin:0;
  /* margin-bottom: 16px; */
  text-align: center;
  font-family: "Roboto Flex";
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
  color: rgba(10, 10, 11, 1);
}

.container_grid_two {
  margin: 10px 0 0 10%;
  display: grid;
  grid-template-columns: repeat(2, 15%);
  gap: 2px;
}

.container_grid_three {
  margin: 10px 2% 0 2%;
  display: grid;
  grid-template-columns: 15% 2% 15%;
  gap: 2px;
}
.container_grid_f {
  /* width: 480px; */
  /* margin: 10px 2% 0 2%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* grid-template-columns: 15% 2% 15%; */
  gap: 2px;
}

.container_grid_four {
  min-width:  1062px;
  /* margin: 1%; */
  display: grid;
  grid-template-columns: 23% 8% 23% 23% 23%;
  /* gap: 15px; */
}
.container_grid_four_budget{
  min-width:  1062px;
  /* margin: 1%; */
  display: grid;
  grid-template-columns: 23% 8% 23% 23% 23%;
  /* gap: 15px; */
}
.container_grid_four_budget_total {
  /* margin: 1%; */
  display: grid;
  grid-template-columns: 23% 77%;
  /* gap: 15px; */
}
.container_grid_seven {
  display: grid;
  grid-template-columns: repeat(7, 14.2%)
}
.container_grid_nine_p {
  display: grid;
  grid-template-columns: repeat(8, 10%) 20%;
}
.container_grid_nine {
  width: 150%;
  display: grid;
  grid-template-columns: repeat(8, 10%) 20%;
}
.container_grid_nine_income {
  /* width: 2500px; */
  min-width: 1480px;
  /* height: 56px; */
  display: grid;
  grid-template-columns:  repeat(8, 10%) 20%;
}

.container_grid_12 {
  display: grid;
  grid-template-columns: repeat(12,8.3%);
  /* gap: 2px; */
  padding: 1px;
  cursor: pointer;
}
.container_grid_12_sales {
  height: 25px;
  display: grid;
  grid-template-columns:  repeat(12, 8.33%);
  /* gap: 2px; */
  padding: 1px;
  cursor: pointer;
}
.container_grid_13 {
  display: grid;
  grid-template-columns: repeat(14, 7.5%);
  gap: 2px;
  cursor: pointer;

}
.container_grid_12_b {
  display: grid;
  grid-template-columns: repeat(12,  89px);
  gap: 8px;
  cursor: pointer;

}
.container_grid_12_budget {
  display: grid;
  grid-template-columns: repeat(12,  89px);
  gap: 8px;
  cursor: pointer;

}
.container_grid_13_f {
  display: grid;
  grid-template-columns: 120px repeat(12, 89px);
  /* gap: 2px; */
  cursor: pointer;
  /* margin-bottom: 44px; */
  gap: 8px;

}
.container_grid_14 {
  display: grid;
  grid-template-columns: 17% repeat(13,6.4%);
  /* gap: 2px; */
  padding: 5px;
}
.container_grid_15 {
  width: 2000px;
  display: grid;
  grid-template-columns: 18% repeat(14,5.85%);
  /* gap: 2px; */
  /* padding: 5px; */
  overflow-y: auto;
  /* margin-bottom: 44px; */
}
.box_shadow{
 box-shadow: 0 0 7px 0.5px #afafaf;
}
select,button{
  cursor: pointer;
}



input {
  outline: none;
  height: 25px;
  caret-color: var(--main-purple);
}

input[type="date"] {
  outline: none;
  height: 27px;
  cursor: pointer;
}
input[type=number]{
  outline: none;
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 select {
  border: none;
  background-color: transparent;
   /* min-height: 30px; */
}
select:focus {
outline: none;
}

select option:hover {
  /*optional rendered */
  background-color: var(--main-purple);
}

.details_select {
  color: white;
}

.third_title {
  /* margin-left: 10%; */
  font-weight: 300;
}

.text_center {
  text-align: center;
}
.text_red{
  color: red;
}

.font_weight_light {
  font-weight: 300;
}

.font_weight_medium {
  font-weight: 500;
}

.headline {
  font-size: var(--font-size-headline);
  line-height: var(--line-height-headline);
}

.category-menu {
  font-size: var(--font-size-category-menu);
  line-height: var(--font-size-category-menu);
}

.category-sub-menu {
  font-size: var(--font-size-category-sub-menu);
  line-height: var(--line-height-sub-menu);
}

.text {
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
}

.cursor_add{
  cursor: copy;
}
