.canceled-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 40px;
    text-align: center;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .canceled-container h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .canceled-container p {
    font-size: 1.1em;
    color: #555;
    margin: 10px 0;
  }
  
  .receipt {
    margin-top: 30px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    text-align: left;
  }
  
  .receipt h3 {
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .receipt p {
    margin: 8px 0;
    font-size: 1.1em;
    color: #444;
  }
  
  .receipt p strong {
    color: #000;
  }
  